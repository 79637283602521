import EzComponent        from '@frontend/components/EzComponent';
import Base               from "@frontend/layout/base"
import Link               from 'next/link'
import { setActiveTheme } from '@frontend/actions';
import { connect }        from 'react-redux';
import RetailContainer    from '@frontend/components/third-party-scripts/misc/retail/RetailContainer';

import '@frontend/less/layout/cms-page/error.less';

class NotFound extends EzComponent{

  render(){
    return(
      <Base>
        <div className="ez-page not-found-page">
          <div className="error-page">
            <img className="bg-image" src="/static/errorbg.png" alt="" />
            <div className="error-page-inner">
              <span className="pre-title">oh!</span>
              <h1>ERROR</h1>
              <span className="sub-title">{this.i18n.t('error.sorry')}</span>
              <Link {...this.router.get('/')}><span className="back-button">{this.i18n.t('error.home')}</span></Link>
            </div>
          </div>
          <RetailContainer key="retail-5ecd144697a528346044f467" blockId="5ecd144697a528346044f467" />
        </div>
      </Base>
    );
  }
}

export default connect(null,{setActiveTheme})(NotFound);

