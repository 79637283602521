import React,{Component}  from 'react';
import Base               from "@frontend/layout/base"
import {cms_page as
        CMSPageStore}     from "@themes/store/frontend/pages/cms_page";
import { getCMSPage }     from '@frontend/actions';
import { setMenuHidden }  from '@frontend/actions/theme';
import { connect }        from 'react-redux';
import dynamic            from "next/dynamic";
import NotFound           from '@frontend/components/page-states/not-found';

const HtmlParser          = dynamic(import("@frontend/components/HtmlParser"));

import '@frontend/less/layout/cms-page.less';

class cms_page extends CMSPageStore{


  render(){
    const { id,cmsPage } = this.props;
    if(!cmsPage){
      return <NotFound/>
    }
    if(cmsPage.page_layout === "empty"){
      return (
        <div className={`ez-page cms-page layout-empty`}>
          <div className={`cms-content`}>
            <HtmlParser html={cmsPage.content}/>
          </div>
        </div>
      );
    }
    let strDate = '';
    this.props.setMenuHidden(true);
    return(
      <Base disablePadding={true}>
        <div className={`ez-page cms-page`}>
          <div className={`cms-content`}>
            <HtmlParser html={cmsPage.content}/>
          </div>
        </div>
      </Base>
    );
  }
}

function mapStateToProps({storeConfig,cmsPage,isMobile}){
  return {
    storeConfiguration: storeConfig,
    cmsPage,
    isMobile
  }
}

export default connect(mapStateToProps,{getCMSPage,setMenuHidden})(cms_page);
