import React,{Component}  from 'react';
import { Query }          from "react-apollo";
import Base               from "@frontend/layout/base"
import EzPublicPage       from "@frontend/components/EzPublicPage";
import HtmlParser         from "@frontend/components/HtmlParser";
import { getCMSPage }     from '@frontend/actions';
import { connect }        from 'react-redux';
import NotFound           from '@frontend/components/page-states/not-found';
import checkServer      from '@frontend/util/checkServer'



import '@frontend/less/layout/cms-page.less';

export class cms_page extends EzPublicPage{
  static async getInitialProps (appContext) {
    let baseProps     = await super.getInitialProps(appContext);

    const { store,client,scriptManager,req,query: { id } } = appContext
    let storeConfig = store.getState().storeConfig;
    if((!storeConfig || !storeConfig.storeId) && req.storeConfig){
      storeConfig = req.storeConfig;
    }
    const variables = {
      id: parseInt(id)
    }
    const cmsPage = await store.dispatch(getCMSPage({client},variables));
    if (!cmsPage){
      if (checkServer() && appContext && appContext.res) {
        appContext.res.statusCode = 404
        //appContext.res.writeHead(404)
      }
      return {cmsPage: false}
    }
    scriptManager.dispatch("CmsPage",cmsPage)
    return {id,cmsPage};
  }

  render(){
    const { id,cmsPage } = this.props;
    if(!cmsPage){
      return <NotFound/>
    }
    return (cmsPage) ?
      <Base>
        <div className="ez-page cms-page">
          <div className="cms-page-title"><strong>{cmsPage.title}</strong></div>
          <div className="cms-content">
            <HtmlParser html={cmsPage.content}  />
          </div>
        </div>
      </Base>
    : <Base></Base>;
  }
}

function mapStateToProps({storeConfig,cmsPage}){
  return {
    storeConfiguration: storeConfig,
    cmsPage
  }
}

export default connect(mapStateToProps,{getCMSPage})(cms_page);
